.basicData {
	width: 100%;
	display: inline-flex;
	justify-content: center;
}

.basicDataField {
	display: inline-flex;
	margin: 0 20px 10px 10px;
	.basicDataSpan {
		font-weight: bold;
		font-size: 25px;
		color: gray;
		margin-top: 25px;
	}
	span {
		font-size: 20px;
		color: gray;
		margin: 25px 10px 0 5px;
	}
	div {
		background-color: transparent;
		span {
			display: none;
		}
	}
}

.mainData {
	width: 100%;
	display: inline-flex;
	justify-content: center;
}

.mainDataCol {
	width: 50%;
	display: inline-flex;
	justify-content: center;
}

.itemsData {
	width: 100%;
	display: grid;
}

.itemsDataTable {
	width: 100% !important;
	margin-top: -50px;
	margin-bottom: 30px;
	padding: 0px;
}

.itemsDataTitle {
	width: 100%;
	color: gray;
	font-weight: bold;
	font-size: 25px;
	margin: 25px 20px 10px 10px;
}

.itemsDataContent {
	width: 100%;
	margin-top: -50px;
}

.itemsDataActions {
	display: inline-flex;
	justify-content: flex-start;
	margin-bottom: 5px;
}

.tabs {
	height: 100px;
}

.data {
	text-align: center;
}

.resumeData {
	display: inline-flex;
	width: 100%;
	margin-top: 20px;
	margin-bottom: 15px;
	justify-content: center;
}

.resumeDataTable {
	border: 2px solid #c0f140;
	padding: 10px;
}

.resumeDataTableTitle {
	width: 100%;
	font-weight: bold;
	font-size: 25px;
	color: gray;
	margin-top: 5px;
	text-align: center;
}

.resumeDataTableField {
	width: 100%;
	color: grey;
	display: inline-flex;
	div {
		width: 50%;
	}
}

.resumeDataTableFieldValue {
	width: 50%;
	text-align: right;
}

.highlighted {
	font-weight: bold;
	color: grey;

	span {
		font-weight: bold;
		color: grey;
	}

	.total {
		font-size: 18px;
	}
}

.printButtonContainer {
	margin-top: 5px;
	text-align: right;
}

.group {
	margin: 25px 0 15px 0;
}

.historyData {
	width: 100%;
	display: grid;
}

.historyDataTitle {
	width: 100%;
	color: gray;
	font-weight: bold;
	font-size: 25px;
	margin: 25px 20px 10px 10px;
}

.historyDataContent {
	width: 100%;
	margin-top: -50px;
}

.historyDataActions {
	display: inline-flex;
	justify-content: right;
}

// Table

.dataContainer {
	display: inline-flex;
	justify-content: center;
}

.table {
	width: 35%;
	margin: 10px;
	border: 2px solid #c0f140;
	padding: 10px;
}

.tableTitle {
	width: 100%;
	color: gray;
	font-weight: bold;
	font-size: 25px;
	margin-bottom: 10px;
	justify-content: center;
	display: inline-flex;
}

.tableContent {
	display: inline-flex;
	width: 100%;
	div {
		:hover,
		:active,
		:focus {
			background-color: transparent;
			text-decoration: none;
		}
		background-color: transparent;
	}
}

.tableCol {
	width: 50%;
	text-align: center;
}

.tableField {
	width: 80%;
	display: grid;
	border-bottom: 1px solid gray;
	margin: 20px 0 10px 30px;
	.tableFieldName {
		font-weight: 300;
		font-size: 14px;
		margin: 3px 0 3px 5px;
		color: gray;
	}
	span {
		width: 100%;
		text-align: left;
		font-size: 16px;
		margin: 0 0 0 5px;
	}
}
